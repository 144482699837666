@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body{font-family: 'Open Sans', sans-serif;}

.loginHeading {
    text-align: center;
    font-weight: 400;
    color: #323a50;
    font-size: 40px;
    margin: 0px;
    text-transform: capitalize;
}
.formError {
    color: red;
}

html:not([dir=rtl]) .wrapper {
    padding-left: var(--cui-sidebar-occupy-start, 0);
}
.pagination .page-item {
      cursor: pointer;
}


.header-nav .dropdown-menu li a{
     cursor: pointer;
     text-decoration: none;
     color: var(--bs-dropdown-link-color);
}

.pagination .active .page-link {
    z-index: 3;
    color: white;
    background-color: #0d6efd;
    border-color: #0d6efd;
}
.noDataFound {
    text-align: center;
    color: red !important;
}
.table th {
     cursor: pointer;
}
.dropdown-menu li a {
    padding: 4px 12px;
    font-size: 13px;
    display: block;
    width: 100%;
}

.btnAdd {
    text-decoration: none;
}

.CustomloginCard{padding: 30px 40px;
    border-radius: 10px !important; border: none !important;
    -webkit-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.1);
    }

.logoWrap{margin-bottom:20px;}

.form-control:disabled{border-color: #e0e7ee !important;
    background-color: #fbfbfb !important;}

input[type='text'],input[type='password'],input[type="date"]{
        border: solid 1px #e0e7ee !important;
        color: #5b5b5b !important;
        padding: 9px 0.75rem !important;
        height: 38px;
}
        
/*select{height: 38px;}*/

.customTextarea{height: 123px;}

textarea{border: solid 1px #e0e7ee !important;}

.customLoginBtn{border-radius: 5px !important;
    background-color: #26873b !important;
    padding: 10px 50px !important;
    border: 1px #26873b solid !important;}

.customLoginBtn:hover{ background-color: #000 !important; border: 1px #000 solid !important;}

.greenText{color:#26873b !important;}

.pageNameHeading{padding-bottom: 22px;}

.form-control:focus{box-shadow: none !important; border-color:#e0e7ee !important;}

.header.header-sticky{-webkit-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.1); border-bottom: none;}

.header .header-divider{border-top: 1px #f5f5f5 solid;}

.footer{    /* box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.1); */
    background: #f8f9fa !important;
    border-top: 1px solid #e6e6e6 !important;}


.breadcrumb .breadcrumb-item.active{font-weight: 600;
    color: #26873b !important;}

.breadcrumb .breadcrumb-item{color: #000 !important; text-decoration: none;}

.sidebar{background: #161824 !important;
    box-shadow: 0 0 10px rgba(28, 39, 60, 0.2) !important;}

.sidebar .sidebar-toggler{background: #0c0e19;}

.sidebar .sidebar-brand{background: #fff;}

.sidebar .sidebar-brand img{ width: 85%;}

.sidebar-nav .nav-group-items{
    padding-bottom: 6px !important;
    padding-top: 6px !important;}

.sidebar-nav .nav-group-items .nav-item{position: relative;}

.sidebar-nav .nav-group-items .nav-item .nav-link{color: #fff !important;
    padding: 8px 43px;
    transition: all 0.5s;
    font-size: 15px;}

.sidebar-nav .nav-group-items .nav-item .nav-link::before{content: "";
    width: 8px;
    height: 2px;
    background: #26873b;
    position: absolute;
    left: 28px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.5s;}

.sidebar-nav .nav-group-items .nav-item .nav-link:hover:before {
        width: 1.25rem;
}

.sidebar-nav .nav-group-items .nav-item .nav-link:hover {
    padding-left: 3.8rem; color: #26873b !important;
}

.sidebar-nav .nav-group.show .nav-group-toggle{background: #26873b; color: #fff !important;}

.sidebar-nav .nav-group.show .nav-group-toggle svg{color: #fff !important;}

/* .sidebar-nav .nav-group-toggle::after{border-top: 0.3125rem solid #2C2C2C;
    border-left: 0.3125rem solid #2C2C2C;
    position: absolute;
    top: 44%;
    border-width: 1px;
    -webkit-transform: rotate(-225deg) translateY(-50%);
    transform: rotate(-225deg) translateY(-50%);
} */

.sidebar-nav .nav-link.active,.sidebar-nav .nav-link.active svg,
.sidebar-nav .nav-link:hover,.sidebar-nav .nav-link:hover svg{color: #fff !important;}

.sidebar-nav .nav-group-items .nav-item .nav-link.active{background: none !important;
    color: #26873b !important;
    font-weight: 500;}


.sidebar-nav .nav-link,.sidebar-nav .nav-link svg{font-size: 15px;
    color: #8b8d9b !important;}

.sidebar-nav{    border-top: 1px #f5f5f5 solid;}

/* .sidebar-nav .nav-link.active::before{content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background-color: #fff;}

.sidebar-nav .nav-item:hover .nav-link::before{width: 3px;}

.sidebar-nav .nav-item:hover .nav-link{color: #fff !important;}

.sidebar-nav .nav-item{position: relative;}

 .sidebar-nav .nav-link.active:hover{width: 3px;  } */

 .card{background: #FFFFFF;
    border-radius: 12px !important;
    border: none !important;
    box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.1);}

.card-header{background-color: #fff !important;
    border-bottom: 1px #e0e7ee solid !important; padding: 18px !important;
border-top-left-radius:12px !important; border-top-right-radius: 12px !important;}

.btnAdd button{ background-color: #26873b2e !important;
    border-color: transparent !important;
    color: #26873b;
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 500; padding: 7px 12px;}
.btnAdd button:hover{background-color: #26873b !important;}
.btn-success{background-color: #26873b !important;
    border-color: #26873b !important;}

.customCardTable thead th{background-color: #F0F4F9;
    border: none;
    white-space: nowrap;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 13px;
    text-transform: uppercase;}

.customCardTable thead tr{border: none;}

.customCardTable tbody td,.customCardTable tbody th{border-left: none;
    border-right: none;
    border-bottom: 1px #E6E6E6 solid;
    white-space: nowrap;
    color: #888888;
    font-size: 15px;
    vertical-align: middle;}

.table-reposnive{overflow-x: scroll; margin-bottom: 12px;}

.actionBtn button{border: none;}

.editBtn{background-color: #daf5e6 !important;
    color: #3AC977 !important;}

.trashBtn{background-color: #ffdede !important;
    color: #FF5E5E !important;}

.viewBtn{background-color: #ffeccc !important;
    color: #FF9F00 !important;}

.actionBtnReport button {
    margin-right: 4px;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    padding: 6px 0;
    line-height: 12px;
    text-align: center;
}
    
.actionBtnReport button svg{width: 11px;}

.searchBtn{background-color: #000 !important;
    color: #fff !important; margin-right: 11px; border-color: #000 !important;
   }

.refreshBtn{border-color: #000 !important;
    color: #000 !important; margin-right: 11px;}

.refreshBtn:hover{background-color: #000 !important; color: #fff !important;}

.filterBtn{background-color: #26873b !important;
    border-color: #26873b !important;}


.filterBtn svg{ margin-top: -4px;}


.form-select{color: #919497 !important;
    padding-right: 35px !important;
    font-size: 15px !important;
    padding-top: 7px !important;}

.form-select:focus{box-shadow: none !important; border-color: #171717 !important;}

.cardHeading{ margin-bottom: 0;
    font-size: 17px; color: #212529;}

.form-label{font-weight: 600;
    font-size: 14px;}

.cardFooter{border-top: 1px #e0e7ee solid !important;
    margin-top: 20px !important;
    padding-top: 20px !important;
    background-color: transparent !important;
    padding-left: 0 !important; padding-bottom: 0 !important;}

/* .linkText{color: #26873b;} */

.form-check .form-check-input{width: 20px !important; height: 20px !important; margin-top: 2px;}

.form-check .form-check-label{padding-left: 3px;}

.form-check .form-check-input:checked {
    background-color: #26873b;
    border-color: #26873b;
}

.customViewTable th{background-color: #F0F4F9 !important;
    white-space: nowrap;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-size: 14px;}

.customViewTable td{ color: #888888; font-size: 15px;}

.card-body{padding: 22px !important;
    padding-top: 15px !important;}

.filterBtn svg{color: #fff !important;}

.btn-ghost-success{color: #3AC977 !important;
    background-color: #daf5e6 !important;
    padding: 1px 17px !important;
    /* font-weight: 500 !important; */
    border-radius: 20px !important;
    font-size: 15px !important;
    min-width: 95px;}

.btn-ghost-danger {
    color: #FF5E5E !important;
    background-color: #ffdede !important;
    padding: 1px 17px !important;
    /* font-weight: 500 !important; */
    border-radius: 20px !important;
    font-size: 15px !important;
    min-width: 95px;
}

.pagination .active .page-link {
    background-color: #000000;
    border-color: #000000;
    color: #fff !important;
}

.page-link{color: #000 !important; padding: 3px 13px !important;}

.page-item:first-child .page-link {
    border-top-left-radius:4px !important;
    border-bottom-left-radius: 4px !important;
}

.page-item:last-child .page-link {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.rightAddBtn{display: flex; justify-content: end;}

.customCardTable input,.customCardTable select{/*margin-bottom: 5px;*/}

.customCardTable .card-body{ padding-top: 19px !important;}

.viewBtn svg{width: 14px !important;}

.form-label .active{color: #26873b; text-decoration: underline;}

.linkText{color: #000;}

.customViewTable a.active{color: #26873b; text-decoration: underline;}

.modal-title{font-size: 17px;}

.footer a{color: #26873b;}

/* Watermark remove css */
g[pointer-events="bounding-box"]{display: none !important;}
/* Watermark remove css */

.nestedLegendWrap{width: 100%; margin: 0 auto;}

.nestedLegend{    list-style: none;
    padding: 0;
    text-align: center;
    margin: 0;}

.nestedLegend li{
    font-size: 12px; padding-right: 18px;
    font-family: "Arial"; display: inline-block;}

.squareBox {
        width: 11px;
        height: 11px;
        display: inline-block;
        margin-right: 6px;
}

.legendBlue{background: #66c2dd;}

.legendYellow{background: #deaf66;}

.legendGreen{background: #a3cc66;}

.legendPink{background: #e685ff;}

.nestedLegend li:last-child{padding-right: 0;}

.userName{text-transform: uppercase; margin-bottom: 0;
    color: #000;}

.header-userinfo{display: flex; align-items: center;}

.userRole{margin-bottom: 0;
    line-height: 17px;
    font-size: 14px;}

.header-media{background: #f7f7f7;
    border-radius: 50%;
    margin-right: 10px;
    color: #a3a3a3;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center; position: relative;}

.header-media svg{font-size: 21px;}

.topRightDropdown{position: absolute;
    z-index: 9;
    background: #63bc70;
    height: 18px;
    width: 18px;
    border-radius: 50px;
    color: #fff;
    bottom: 0px;
    right: -1px;
    line-height: 16px;
    display: flex;
    align-items: end;
    justify-content: center;}

.topRightDropdown svg{font-size: 16px;}

.dropdown-menu{box-shadow: 0 0 3.125rem 0 rgba(82, 63, 105, 0.15);
    border-radius: 0px 0px 0.375rem 0.375rem;border: none !important;
    top: 8px !important;}

.dropdown-menu li a svg{color: #26873b;}

.dropdown-divider{border-top: 1px solid #E6E6E6;}

.cardStatusDiv{display: flex; flex-wrap: wrap; width: 100%;}

.statusIcon{flex: 0 0 auto;
    width: 20%;
    text-align: end;
    margin-right: 15px;}

.statusInfo{flex: 0 0 auto;
    width: 75%;}


.textYellowX{color: #ff9c0d;}

.textBlue{color: #0193e1;}

.textGreenX{color: #35b868;}

.textRedX{color: #b82e2e;}

.statusValue{ font-size:24px;    margin-bottom: 4px;}

.statusName{color: #191919;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;}

.statusIcon img{}

.cardStatusDiv:nth-child(2) .statusIcon img{width: 51px;}

.customOneFilters{display: flex;
    justify-content: space-between;}

.reportListCss .mobilemarginSpace{margin-bottom: 20px !important;}
.problemDetailCss .mobilemarginSpace{margin-bottom: 20px !important;}

.allCommentList .card-title{color: #26873b; font-size: 17px;}

.allCommentList .card-text{color: #c0c0c0; margin-bottom: 5px; font-size: 14.5px;}

.allCommentList .card-text-p{color: #000; font-size: 15px !important;}

.forMobileViewN{display: none !important;}

.mobilemarginSpace{margin-bottom: 0 !important;}

.solidCircle{    width: 11.5px;
    height: 11.5px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    right: 40px;
    top: 18px;}

.redStatus{background:#fd0002;}

.yellowtatus{background:#fefd02;}

.greenStatus{background:#01ae20;}
.notesWidth a { color: #26873b !important;
    cursor: pointer;}
    
    .list-item {
        display: flex;
        align-items: center;
        /* background-color: #f4f4f4; */
        padding: 10px;
        margin: 5px;
      }
      
      .item-text {
        flex-grow: 1;
      }
      .item-text a{
        text-decoration: none;
      }
      .list-item .trashBtn {
           border: none;
      }
      .uploadedDocumentList {
         background: #f4f4f4; 
      }
      
     
@media only screen and (max-width: 1440px) and (min-width:1200px) {
.statusInfo{ width: 73%;}

.problemDetailCss .mobileSpace{margin-bottom: 20px;}

.fieldFilters .col-xl-3{width: 33.33333333%;}

.fieldFilters .col-xl-3.customOneFilters{width: 100%;}

.problemCss .col-xl-8,.problemCss .customOneFilters{width: 100%;}

.problemCss .customOneFilters,.fieldFilters .customOneFilters{margin-top: 20px;}
}

@media (max-width: 1024px) {
    .graphFilterDrop .col-2{width:30%;}
    .statusInfo{width: 72%;}
    .cardStatusDiv{align-items: center;}
    .statusIcon{margin: 0 auto;}
    .statusIcon img{width:100% !important;}
    .statusValue{margin-bottom: 0;}
    .cardStatusDiv:nth-child(1) .statusIcon{width: 15%;}
}

@media only screen and (max-width: 1180px) and (min-width:768px) {
/* .rightAddBtn svg{display: none;} */
.problemCss .col-xl-8  .col-xl-4{ width: 33.33%;}

.CustomloginWrap .col-md-5{width: 75%;}
}

@media only screen and (max-width: 1200px) and (min-width:1180px) {
.statusInfo{width: 72%;}
.statusIcon img{width:100% !important;}
}

@media (max-width: 1180px) {

.marginBtmMobile{margin-bottom: 20px;}
}

@media only screen and (max-width: 1180px) and (min-width:844px) {
   
    .fieldFilters .col-12{ width:33.33%;}

    .fieldFilters .col-12.customOneFilters{width: 100%;}
    .userListCss .col-3{width: 50%;}
    .userListCss .col-6{width: 100%;}
    .userListCss .customOneFilters{margin-top: 20px;}
}

@media only screen and (max-width: 1366px) and (min-width:769px) 
{
    .forMobileViewN{display: none !important; }
    .mobileSpace{margin-bottom: 20px !important;}
}

@media only screen and (max-width: 900px) and (min-width:320px) {
.mobilemarginSpace {margin-bottom: 20px !important;}
}

@media (max-width: 768px) {

.showPageNum .col-4{flex: 0 0 auto;
    width: 100%;}
.forMobileViewN .rightAddBtn {margin-top:0 !important; display: flex; justify-content: flex-end !important;}
.forMobileViewB{display: none;}

.customCardTable .card-header .col-3,.customCardTable .card-header .col-6{width: 100%;}
.customViewMobile .col-4,.customViewMobile .col-6{width: 100%;}
.graphFilterDrop .col-2{width: 50%;}
.statusIcon img{width:100% !important;}
.dashboardStatus .col-3:nth-child(1){margin-bottom: 20px;} 
.dashboardStatus .col-3{ width: 50%;}
.mobilemarginSpace{margin-bottom: 20px;}
.mobileHeaderView {flex-wrap: nowrap !important;}
.header-info{display: none;}
.header-media{margin-right: 0;}
.forMobileViewN{display: block !important; }
}

@media (max-width: 767px) {
    .rightAddBtn{margin-top: 15px; justify-content: inherit;}
    .customOneFilters .rightAddBtn{margin-top: 0;}
}

@media (max-width: 475px) {
.btnAdd button svg{display: none;}
.formResponsiveCol .col-6{width: 100%;}
.mobBigbtn{display: block;}
.mobBigbtn .rightAddBtn{margin-top: 15px;}
}

@media (max-width: 360px) {
.btnAdd button{padding: 7px 7px;}
}

@media (max-width: 320px) {
.dashboardStatus .col-3 {
    width: 100%;
    margin-bottom: 20px;
}
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #000 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 6px;
    height: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #f8f8f8;
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgb(89, 89, 89);
    /* background-color: #328843; */
    border-radius: 10px;
    border: 2px solid #f8f8f8;
  }

@media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1600px !important;
    }
}